<template>
  <div>
    <v-alert type="error" v-if="showError" class="my-2" v-html="errorMsg">
    </v-alert>
    <v-alert type="success" v-if="showSuccess" class="my-2" v-html="successMsg">
    </v-alert>
    <v-alert type="success" v-if="showRecoverMessage" class="my-2">
      <v-row align="center">
        <v-col class="grow">
          La teva sol.licitud de recuperació de contrassenya s'ha enviat
          correctament. En breu rebràs un email amb les instruccions per a
          ressetejar el teu password.
        </v-col>
        <v-col class="shrink">
          <v-btn color="white" outlined @click.stop="goHome()">OK</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow"
    ></v-progress-linear>
    <v-tabs
      v-if="!showRecoverPasswordForm"
      v-model="tabItem"
      show-arrows
      icons-and-text
      grow
      light
    >
      <v-tabs-slider color="red darken-1"></v-tabs-slider>
      <v-tab v-for="(i, index) in tabs" :key="index">
        <v-icon large>{{ i.icon }}</v-icon>
        <div class="caption py-1">{{ i.name }}</div>
      </v-tab>
      <!-- Login Tab -->
      <v-tab-item>
        <v-card class="px-md-2">
          <v-card-text>
            <v-form
              ref="loginForm"
              @submit.prevent="submitLogin"
              v-model="validLoginForm"
            >
              <v-row justify="center">
                <v-col cols="12" md="8" lg="7">
                  <v-text-field
                    v-model.trim="loginForm.email"
                    :rules="loginEmailRules"
                    label="E-mail"
                    required
                    autocomplete="email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="8" lg="7">
                  <v-text-field
                    v-model.trim="loginForm.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    autocomplete="password"
                    name="input-pw-lg"
                    label="Password"
                    required
                    hint="Mínim 8 caràcters"
                    counter
                    @click:append="show1 = !show1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="5" order-md="last">
                  <v-btn
                    x-large
                    block
                    :disabled="validLoginForm === false"
                    color="success"
                    @click="submitLogin"
                  >
                    Entrar
                  </v-btn>
                </v-col>
                <v-col cols="12" md="7" order-md="first">
                  <v-btn
                    text
                    color="blue"
                    small
                    @click="showRecoverPasswordForm = true"
                  >
                    No recordes el Password?
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- Register Tab -->
      <v-tab-item>
        <v-card class="px-4">
          <v-card-text>
            <v-form
              ref="registerForm"
              @submit.prevent="submitRegister"
              v-model="validRegisterForm"
            >
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model.trim="registerForm.name"
                    :rules="[rules.required]"
                    label="Nom"
                    maxlength="20"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model.trim="registerForm.surnames"
                    :rules="[rules.required]"
                    label="Cognoms"
                    maxlength="60"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model.trim="registerForm.phone"
                    :rules="phoneRules"
                    label="Telèfon"
                    maxlength="9"
                    required
                    hint="Telèfon 9 dígits sense espais"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model.trim="registerForm.dni"
                    :rules="dniRules"
                    label="DNI/NIE/Passaport"
                    maxlength="9"
                    hint="Document d'identitat sense espais i amb lletra"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="registerForm.email"
                    :rules="emailRules"
                    label="E-mail"
                    autocomplete="email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.trim="registerForm.password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show2 ? 'text' : 'password'"
                    label="Password"
                    autocomplete="password"
                    name="input-pw-rg"
                    required
                    hint="Mínim 8 caràcters"
                    counter
                    @click:append="show2 = !show2"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    block
                    v-model.trim="registerForm.repassword"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, passwordMatchRegister]"
                    :type="show2 ? 'text' : 'password'"
                    label="Confirma Password"
                    autocomplete="repeat-password"
                    name="input-rpw-rg"
                    required
                    counter
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                  <v-btn
                    large
                    block
                    :disabled="validRegisterForm === false"
                    color="success"
                    @click="submitRegister"
                    >Enviar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <!-- Password Recover -->
    <v-card v-if="showRecoverPasswordForm" grow class="px-md-4 pt-1">
      <v-card-title class="title-1 grey--text text-center">
        <span class="mx-auto">Recuperació de Contrassenya</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="recoverFormRequest"
          @submit.prevent="submitRecoverRequest || submitResetPassword"
          v-model="validRecoverForm"
        >
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="recoverForm.email"
                :rules="loginEmailRules"
                label="E-mail"
                required
                autocomplete="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="recoverForm.phone"
                :rules="[rules.required]"
                label="Telèfon"
                maxlength="20"
                required
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="4">
              <v-btn
                text
                color="orange darken-1"
                @click="showRecoverPasswordForm = false"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                x-large
                block
                :disabled="validRecoverForm === false"
                color="success"
                @click="submitRecoverRequest"
              >
                Enviar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "login-register-form",

  props: ["tab"],

  data: () => ({
    dialog: true,
    tabItem: 0,
    tabs: [
      { name: "Entrar", icon: "mdi-account" },
      { name: "Registrar-se", icon: "mdi-account-outline" }
    ],
    validLoginForm: false,

    errorMsg: "Oops! Alguna cosa no ha anat bé. Torna-ho a provar més tard.",
    showError: false,
    successMsg: "S'ha completat el registre correctament",
    showSuccess: false,
    showRecoverMessage: false,

    loginForm: {
      email: "",
      password: ""
    },

    validRegisterForm: false,

    validRecoverForm: false,

    registerForm: {
      name: "",
      surnames: "",
      phone: "",
      dni: "",
      email: "",
      password: "",
      repassword: ""
    },

    recoverForm: {
      name: "",
      surnames: "",
      phone: "",
      email: "",
      password: "",
      repassword: ""
    },

    loginPassword: "",
    loginEmail: "",
    loginEmailRules: [
      v => !!v || "Requerit",
      v => /.+@.+\..+/.test(v) || "E-mail ha de ser valid"
    ],
    emailRules: [
      v => !!v || "Requerit",
      v => /.+@.+\..+/.test(v) || "E-mail ha de ser valid"
    ],
    phoneRules: [
      v => !!v || "Requerit. (9 dígits sense espais)",
      v =>
        /[0-9]{9}/.test(v) ||
        "El telèfon ha de ser vàlid (9 dígits sense espais)"
    ],
    // dniRules: [
    //   v =>
    //     /^[XYZ]?\d{5,8}[A-Z]$/.test(v) || "El DNI/NIE/Passaport ha de ser vàlid"
    // ],
    show1: false,
    show2: false,
    show3: false,

    rules: {
      required: value => !!value || "Requerit",
      min: v => (v && v.length >= 8) || "Min 8 caràcters"
    },

    loading: false,

    showRecoverPasswordForm: false,
    userInfo: null
  }),

  computed: {
    passwordMatchRegister() {
      return () =>
        this.registerForm.password === this.registerForm.repassword ||
        "El Password ha de coincidir";
    },
    dniRules() {
      const rules = [];
      if (this.registerForm.dni) {
        const rule = v =>
          (v && this.validateDNI(v) === true) ||
          "El DNI/NIE/Passaport ha de ser vàlid";

        rules.push(rule);
      }
      return rules;
    }
  },

  mounted() {
    this.tabItem = this.tab;
  },

  methods: {
    ...mapActions(["login", "retrieveToken", "registerUser", "forgotPassword"]),

    validateLoginForm() {
      return this.$refs.loginForm.validate();
    },

    validateRegisterForm() {
      return this.$refs.registerForm.validate();
    },

    // reset() {
    //   this.$refs.form.reset();
    // },

    // resetValidation() {
    //   this.$refs.form.resetValidation();
    // },

    // Comprueba si es un DNI correcto (entre 5 y 8 letras seguidas de la letra que corresponda).
    // Acepta NIEs (Extranjeros con X, Y o Z al principio)
    validateDNI(dni) {
      var numero, n, letra;
      var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

      dni = dni.toUpperCase();

      if (expresion_regular_dni.test(dni) === true) {
        numero = dni.substr(0, dni.length - 1);
        numero = numero.replace("X", 0);
        numero = numero.replace("Y", 1);
        numero = numero.replace("Z", 2);
        n = dni.substr(dni.length - 1, 1);
        numero = numero % 23;
        letra = "TRWAGMYFPDXBNJZSQVHLCKET";
        letra = letra.substring(numero, numero + 1);
        if (letra != n) {
          // console.log("Dni erroneo, la letra del NIF no se corresponde");
          return false;
        } else {
          // console.log("Dni correcto");
          return true;
        }
      } else {
        // console.log("Dni erroneo, formato no válido");
        return false;
      }
    },

    async submitLogin() {
      const credentials = {
        email: this.loginForm.email,
        password: this.loginForm.password
      };
      this.loading = true;
      try {
        await this.login(credentials);
        if (this.$store.getters.loggedIn) {
          this.$router.push({ path: "/" }).catch(() => {});
          this.showError = false;
          this.loading = false;
        } else {
          this.errorAlert("Usuari incorrecte o inexistent");
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.errorAlert(
          "Oops! Sembla que hi ha algún problema de comunicació amb el servei"
        );
      }
    },

    async submitRegister() {
      let userData = this.registerForm;
      this.loading = true;
      try {
        let resp = await this.registerUser(userData);
        if (resp === 1) {
          this.showError = false;
          this.successAlert(
            "El registre s'ha completat correctament. Gràcies!"
          );
        } else {
          this.errorAlert(
            "Ooops! No s'ha pogut completar el registre. <br /> Motiu: " +
              resp.message_error
          );
        }
        this.loading = false;
      } catch (error) {
        console.log(JSON.stringify(error));
        this.errorAlert("Ooops! S'ha produït un error inesperat");
      }
    },

    errorAlert(msg) {
      this.errorMsg = msg;
      this.showError = true;
      var v = this;
      setTimeout(function() {
        v.showError = false;
      }, 5000);
      this.loading = false;
    },

    successAlert(msg) {
      this.successMsg = msg;
      this.showSuccess = true;
      var v = this;
      setTimeout(function() {
        v.showSuccess = false;
      }, 4000);
      this.loading = false;
      this.$router.push({ path: "/" }).catch(() => {});
    },

    async submitRecoverRequest() {
      let formData = this.recoverForm;
      let userData = {
        email: formData.email,
        phone: formData.phone
      };
      this.loading = true;
      try {
        let respData = await this.forgotPassword(userData);
        if (respData.data[0] === "user") {
          this.showRecoverMessage = true;
          this.showError = false;
          this.showRecoverPasswordForm = false;
        } else {
          this.errorAlert(
            "Ooops! No s'ha pogut completar la petició de reseteig de contrassenya. <br /> Motiu: " +
              respData.message_error
          );
        }
        this.loading = false;
      } catch (error) {
        this.errorAlert("Ooops! S'ha produït un error inesperat");
      }
    },

    goHome() {
      this.showRecoverMessage = false;
      this.$router.push({ path: "/" }).catch(() => {});
    }
  }
};
</script>
