<template>
  <v-container class="fill-height">
    <v-row class="text-center">
      <v-col cols="12" class="mb-2" align-self="start">
        <h2 class="header font-weight-bold my-2 white--text">
          GAUDEIX DE TOTS ELS AVANTATGES DE L'APP!
        </h2>

        <v-spacer></v-spacer>

        <p class="subheading font-weight-regular mb-5 white--text">
          Per gaudir de tota la funcionalitat et recomanem que et registri's i
          t'identifiquis com a usuari.
        </p>
      </v-col>
    </v-row>

    <v-row justify="center" class="text-center">
      <v-col sm="12" md="8">
        <LoginRegisterForm :tab="tab" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="80"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginRegisterForm from "@/components/LoginRegisterForm.vue";
export default {
  name: "login-register",

  components: { LoginRegisterForm },

  data: () => ({
    // logged: false
    tab: 0
  })
};
</script>
